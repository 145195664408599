import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import RobotMagnifyingImage from "../../assets/images/robot-magnifying.png";
import JigsawImage from "../../assets/images/jigsaw.png";
import TwoRobotsImage from "../../assets/images/two-robots.png";
import DesignPhoneImage from "../../assets/images/design-phone.png";
import Layout from "../../components/Layout";
import { SEO } from "../../components/SEO";
import { PageOuter } from "../../components/PageOuter";
import Spacer from "../../components/Spacer";
import {
  SectionContainer,
  SectionRow,
  SectionRowImageContainer,
  SectionRowImageStyled,
} from "../../components/SectionStyles";
import PageIntro from "../../components/PageIntro";

const Services = (): // { data }: { data: SilentAny }
JSX.Element => {
  useEffect(() => {
    Aos.init({
      // can pass aos global animations here
      // alternatively, pass attribute to element directly e.g. data-aos-duration="2000"
      duration: 1500,
      once: true,
      mirror: false,
    });
  }, []);

  const title = "Services";
  return (
    <Layout>
      <SEO title={title} />
      <PageOuter title={title} midLayout>
        <SectionContainer>
          <PageIntro
            title="What we do"
            intro="We produce award-winning apps and websites for some of the biggest
              brands in the world. Building products that fit you and your
              customer needs is what we do."
          />
          <SectionRow>
            <div tabIndex={0}>
              <h3 data-aos="fade">
                Research <br />
                &#38; Design
              </h3>
              <div
                data-aos="fade-right"
                data-aos-duration={750}
                data-aos-easing="ease-out-quad"
              >
                <p>
                  Our design researchers carefully investigate human experience
                  and behaviour, curate ways to spark and distill insight, and
                  inspire teams and clients to address people’s needs through
                  bold, well researched design.
                </p>

                <ul>
                  <li>Usability Testing</li>
                  <li>Information Architecture</li>
                  <li>Accessibility Audits</li>
                  <li>Journey Mapping</li>
                  <li>Brand &#38; Voice</li>
                </ul>
                {/* <Spacer height={1} />
                <ArrowLink
                  to="/services/research-and-design"
                  ariaLabel="Clickable link to the research and design services page"
                  
          
                >
                  Learn more
                </ArrowLink> */}
              </div>
            </div>
            <SectionRowImageContainer>
              <SectionRowImageStyled
                data-aos="fade"
                src={RobotMagnifyingImage}
                alt="An illustration of a robot looking through a magnifying glass at a screen"
              />
            </SectionRowImageContainer>
          </SectionRow>

          <SectionRow>
            <div tabIndex={0}>
              <h3 data-aos="fade">
                Development <br />
                &#38; Testing
              </h3>
              <div
                data-aos="fade-left"
                data-aos-duration={750}
                data-aos-easing="ease-out-quad"
              >
                <p>
                  Our world class development and QA team refine complex
                  problems into simple, human friendly solutions, ensuring your
                  product works seamlessly for you and your customers.
                </p>

                <ul>
                  <li>App Development</li>
                  <li>Accessibility Engineering</li>
                  <li>Cloud &#38; DevOps</li>
                  <li>Manual &#38; Automated Testing</li>
                  <li>System Architecture</li>
                  <li>Managed Services</li>
                </ul>
                {/*  <Spacer height={1} />
                <ArrowLink
                  to="/services/development-and-testing"
                  ariaLabel="Clickable link to the development and testing services page"
                >
                  Learn more
                </ArrowLink> */}
              </div>
            </div>
            <SectionRowImageContainer>
              <SectionRowImageStyled
                data-aos="fade"
                src={JigsawImage}
                alt="An illustration of a computer connected by a cable to a jigsaw"
              />
            </SectionRowImageContainer>
          </SectionRow>

          <SectionRow>
            <div tabIndex={0}>
              <h3 data-aos="fade">
                Analytics <br />
                &#38; Optimisation
              </h3>
              <div
                data-aos="fade-right"
                data-aos-duration={750}
                data-aos-easing="ease-out-quad"
              >
                <p>
                  Gain a deep understanding of how your users engage with your
                  platform. Effortlessly check the performance of your
                  marketing, content, products and more.
                </p>

                <ul>
                  <li>Testing</li>
                  <li>Tool Setup</li>
                  <li>Behaviour Reporting</li>
                  <li>Traffic Monitoring</li>
                  <li>User Feedback Reporting</li>
                  <li>A&#47;B Testing</li>
                </ul>
                <Spacer height={1} />
                {/* <ArrowLink
                  to="/services/analytics-and-optimisation"
                  ariaLabel="Clickable link to the analytics and optimisation services page"
                >
                  Learn more
                </ArrowLink> */}
              </div>
            </div>
            <SectionRowImageContainer>
              <SectionRowImageStyled
                data-aos="fade"
                src={DesignPhoneImage}
                alt="An illustration of a mobile phone surrounded by various charts and diagrams"
              />
            </SectionRowImageContainer>
          </SectionRow>

          <SectionRow>
            <div tabIndex={0}>
              <h3 data-aos="fade">
                Digital
                <br />
                Strategy
              </h3>
              <div
                data-aos="fade-left"
                data-aos-duration={750}
                data-aos-easing="ease-out-quad"
              >
                <p>
                  We can help you curate an in-depth business strategy to turn
                  an idea into a successful business.
                </p>

                <ul>
                  <li>Training</li>
                  <li>Road Mapping</li>
                  <li>Product Lifecycle Development</li>
                  <li>Project Guidance</li>
                  <li>Competitor Analysis</li>
                  <li>Early Concept Development</li>
                </ul>
                <Spacer height={1} />
                {/* <ArrowLink
                  to="/services/digital-strategy"
                  ariaLabel="Clickable link to the digital strategy services page"
                >
                  Learn more
                </ArrowLink> */}
              </div>
            </div>
            <SectionRowImageContainer>
              <SectionRowImageStyled
                data-aos="fade"
                src={TwoRobotsImage}
                alt="An illustration of two retro robots shaking hands"
              />
            </SectionRowImageContainer>
          </SectionRow>
        </SectionContainer>
      </PageOuter>
    </Layout>
  );
};
// Using direct imports rather that query from Gatsby because gatby-image would not take fixed height with auto width for the section images
// export const query = graphql`
//   {
//     designPhoneImage: file(relativePath: { eq: "design-phone.png" }) {
//       childImageSharp {
//         fluid(maxWidth: 750) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//     robotLMagnifyingImage: file(relativePath: { eq: "robot-magnifying.png" }) {
//       childImageSharp {
//         fluid(maxWidth: 750) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//     jigsawImage: file(relativePath: { eq: "jigsaw.png" }) {
//       childImageSharp {
//         fluid(maxWidth: 750) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//     twoRobotsImage: file(relativePath: { eq: "two-robots.png" }) {
//       childImageSharp {
//         fluid(maxWidth: 750) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `;

export default Services;
